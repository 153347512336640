<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.0415 5.20831L13.4047 2.84515C14.0555 2.19428 15.1108 2.19428 15.7617 2.84515L17.1547 4.23813C17.8055 4.889 17.8055 5.94428 17.1547 6.59515L14.7915 8.95831M11.0415 5.20831L2.77966 13.4702C2.4671 13.7827 2.2915 14.2066 2.2915 14.6487V17.7083H5.35115C5.79318 17.7083 6.2171 17.5327 6.52966 17.2202L14.7915 8.95831M11.0415 5.20831L14.7915 8.95831"
            :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
    name: 'EmbedIcon',
    props: {
        width: {
            type: Number,
            default: 20
        },
        height: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: '#fff'
        }
    }
}
</script>

<style scoped></style>