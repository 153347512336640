<template>
    <svg :width="width" :height="height" :class="className" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.68">
            <path
                d="M16.875 9.99999L7.5 9.99999M16.875 9.99999L13.125 13.75M16.875 9.99999L13.125 6.25M9.375 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083L3.125 4.79167C3.125 3.87119 3.87119 3.125 4.79167 3.125L9.375 3.125"
                class="stroke-current" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'ForeplayIcon',
    props: {
        width: {
            type: Number,
            default: 20
        },
        height: {
            type: Number,
            default: 20
        },
        className: {
            type: String,
            default: 'text-icon-normal'
        }
    }
}
</script>

<style scoped></style>